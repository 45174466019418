import React, {Component} from 'react';
import axios from "axios";

class Survey extends Component {
    constructor(props){
        super(props);
        const {
            surveyQuestionDataList,
            surveyDefinitionId,
            reservationId,
        } = props;

        this.state = {
            surveyQuestionDataList,
            surveyDefinitionId,
            reservationId,

            currentQuestionIndex: 0,
            surveyReservationId: null,
            errorOnSave: false,
            errorLenghtOnSave: false,
            text: '',
        };
    }

    displayTransition = () => {
        setTimeout(function () {
            if (document.getElementById('question') != null) {
                document.getElementById('question').classList.add("slidein");
            }
        }, 100);
    };

    saveAnswer = async (surveyQuestionOptionId) => {
        const {
            surveyReservationId,
            surveyDefinitionId,
            reservationId,
            surveyQuestionDataList,
            currentQuestionIndex,
        } = this.state;

        const surveySaveAnswerRequest = {
            surveyReservationId: surveyReservationId,
            surveyDefinitionId: surveyDefinitionId,
            reservationId: reservationId,
            surveyQuestionId: surveyQuestionDataList[currentQuestionIndex].surveyQuestion.id,
            surveyQuestionOptionId: surveyQuestionOptionId
        };

        const response = await axios.post("/ws/surveysapi/saveSurveyAnswer", surveySaveAnswerRequest);
        const surveySaveAnswerResponse = !!response && response.data;
        if(!!surveySaveAnswerResponse){
            if (!surveySaveAnswerResponse.surveyReservationId) {
                this.setState({errorOnSave: true})
            } else {
                this.setState({
                    errorOnSave: false,
                    errorLenghtOnSave: false,
                    surveyReservationId: surveySaveAnswerResponse.surveyReservationId,
                    currentQuestionIndex: currentQuestionIndex + 1,
                })
            }
            if (document.getElementById('question') != null) {
                document.getElementById('question').style.display = 'none';
                document.getElementById('question').classList.remove("slidein");
                this.displayTransition();
            }
        } else {
            this.setState({errorOnSave: true})
        }
    };

    saveTextAnswer = async () => {
        const {
            text,
            surveyReservationId,
            surveyDefinitionId,
            reservationId,
            surveyQuestionDataList,
            currentQuestionIndex,
        } = this.state;

        if (!!text && text.length > 0) {
            if (text.length < 1000) {
                const surveySaveTextAnswerRequest = {
                    surveyReservationId: surveyReservationId,
                    surveyDefinitionId: surveyDefinitionId,
                    reservationId: reservationId,
                    surveyQuestionId: surveyQuestionDataList[currentQuestionIndex].surveyQuestion.id,
                    text
                };

                const response = await axios.post("/ws/surveysapi/saveSurveyTextAnswer", surveySaveTextAnswerRequest);
                const surveySaveAnswerResponse = !!response && response.data;
                if(!!surveySaveAnswerResponse) {
                    if (!surveySaveAnswerResponse.surveyReservationId) {
                        this.setState({errorOnSave: true})
                    } else {
                        this.setState({
                            errorOnSave: false,
                            errorLenghtOnSave: false,
                            surveyReservationId: surveySaveAnswerResponse.surveyReservationId,
                            currentQuestionIndex: currentQuestionIndex + 1,
                        })
                    }
                    if (document.getElementById('question') != null) {
                        document.getElementById('question').style.display = 'none';
                        document.getElementById('question').classList.remove("slidein");
                        this.displayTransition();
                    }
                } else {
                    this.setState({errorOnSave: true,})
                }
            } else {
                this.setState({errorLenghtOnSave: true,});
            }
        } else {
            this.continueWithountAnswer();
        }
    };
    continueWithountAnswer = () => {
        this.setState({
            errorOnSave: false,
            currentQuestionIndex: this.state.currentQuestionIndex + 1
        });
        if (document.getElementById('question') != null) {
            document.getElementById('question').style.display = 'none';
            document.getElementById('question').classList.remove("slidein");
            this.displayTransition();
        }
    };

    onTextChange = ({currentTarget: {value}}) => {
        console.log({value});
        this.setState({text: value})
    };

    render() {
        const {surveyQuestionDataList, currentQuestionIndex, text, errorOnSave, errorLengthOnSave} = this.state;
        return (
            <React.Fragment>
                { surveyQuestionDataList != null &&  currentQuestionIndex < surveyQuestionDataList.length ? (
                    <div className="" id={"question"}>
                        <h2>{surveyQuestionDataList[currentQuestionIndex].surveyQuestion.question}</h2>
                        {surveyQuestionDataList[currentQuestionIndex].surveyQuestion.surveyQuestionType === 'TEXT'? (
                            <React.Fragment>
                                <textarea className="textarea" onChange={this.onTextChange} value={text}/>
                                <a className="submit" onClick={this.saveTextAnswer}>Submit</a>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="answer">
                                    {surveyQuestionDataList[currentQuestionIndex].surveyQuestionOptionList.map((surveyQuestionOption, idx) => {
                                        return <a key={idx} className="option" onClick={()=>this.saveAnswer(surveyQuestionOption.id)}>
                                            {surveyQuestionOption.text}
                                        </a>
                                    })}

                                    {surveyQuestionDataList[currentQuestionIndex].surveyQuestion.surveyQuestionType === 'RANKING' &&
                                        <a className="option" onClick={this.continueWithountAnswer}>
                                            NA
                                        </a>
                                    }
                                </div>
                                {surveyQuestionDataList[currentQuestionIndex].surveyQuestion.hint !== null &&
                                    <div className="hint">
                                        {surveyQuestionDataList[currentQuestionIndex].surveyQuestion.hint}
                                    </div>
                                }

                            </React.Fragment>
                        )}
                        {!!errorOnSave && <div className="error">An error occurred. Please try again later.</div>}
                        {!!errorLengthOnSave && <div className="error">The text is to large.</div>}
                    </div>
                ):(
                    <h2>Survey Complete. Thank you.</h2>
                )}
            </React.Fragment>
        )
    }
}

export default Survey;
