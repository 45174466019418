import React, {Component} from 'react';
import axios from "axios";
import qs from "query-string";
import Survey from "./survey";

class Home extends Component {
    constructor(props){
        super(props);
        const { location:{search} } = this.props;
        const qstring = qs.parse(search);
        const reservationId = qstring.r;
        const uniqueSurveyId = qstring.sid;

        const initialState = {
            errorMessage: null,
            reservationId: reservationId,
            surveyDefinitionId: null,
            surveyQuestionDataList: null,
            propertyName: null
        };

        if(!!reservationId && !!uniqueSurveyId){
            this.state = {...initialState,};
            this.loadInitData(reservationId, uniqueSurveyId);
        } else {
            this.state={
                ...initialState,
                errorMessage: "Launch URL doesn't have enough information to display a survey.",
            }
        }
    }

    loadInitData = async (reservationId, uniqueSurveyId) => {
        try{
            const response = await axios.get("/ws/surveysapi/survey?reservationId=" + reservationId + "&uniqueSurveyId=" + uniqueSurveyId);
            const surveyResponse = !!response && response.data;

            if (!!surveyResponse) {
                this.setState({
                    errorMessage: surveyResponse.errorMessage,
                    surveyDefinitionId: surveyResponse.surveyDefinitionId,
                    propertyName: surveyResponse.propertyName,
                    surveyQuestionDataList: surveyResponse.surveyQuestionDataList,
                });
            } else {
                this.setState({errorMessage: 'Error loading survey information.'});
            }
        } catch(e) {
            this.setState({errorMessage: 'Error loading survey information.'});
        }
    };

    renderErrorMessage = (errorMessage) => <div><h2>{errorMessage}</h2></div>;

    render() {
        const {errorMessage, surveyQuestionDataList, reservationId, surveyDefinitionId} = this.state;

        return (
            <React.Fragment>
                <div className="content">
                    {errorMessage !== null ? (
                        this.renderErrorMessage(errorMessage)
                    ) : (
                        surveyQuestionDataList === null ? (
                            <div><img alt="loading" src="./assets/loading.gif"/></div>
                        ) : (
                            <Survey surveyQuestionDataList={surveyQuestionDataList}
                                    surveyDefinitionId={surveyDefinitionId}
                                    reservationId={reservationId}/>
                        )
                    )}
                </div>
            </React.Fragment>
        )
    }
}

export default Home;
